export const slots: { [key: number]: number } = {
  1: -25,
  2: 50,
  3: 75,
  4: 125,
  5: 250,
  6: 125,
  7: 75,
  8: 50,
  9: -25
}
