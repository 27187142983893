import { FC } from 'react';

import styles from './PlinkoHeader.module.scss';

interface PlinkoHeaderProps {
  addBall: () => void;
}

const PlinkoHeader: FC<PlinkoHeaderProps> = ({ addBall }) => {
  const handleAddBallClick = () => {
    addBall();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  return (
    <div className={styles.headerContainer}>
      <button onClick={handleAddBallClick}></button>
    </div>
  );
};

export default PlinkoHeader;
